import React, { useLayoutEffect, useRef } from 'react'

function Footer() {
    const ref = useRef();
    return (
        <footer ref={ref}>
            <div className="container wide">
                Website by <a href="https://www.linkedin.com/in/mutalov/">Sarvar Mutalov</a>
            </div>
        </footer>
    )
}

export default Footer