import "./App.css";
import "./fonts.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Suspense } from "react";
import { concerts } from "./utils/CONSTANTS";

function App() {
  return (
    <>
      <Suspense fallback={<>loading</>}>
        <div className="navbar">
          <div className="container">
            <div className="nav">
              {/* <a href="#">asosiy</a> */}
              {/* <a href="#afisha">afisha</a> */}
            </div>
          </div>
        </div>
        <Header />
        <main>
          <div id="about" className="section">
            <div className="container content text-center">
              <p>
                <b>Mirshakar Fayzulloyev</b> — stand-up komik, O‘zbekistondagi
                yagona
                <b> SAHNA stand-up klub</b> asoschisi.
              </p>
              <br />
              <p>
                Qariyb 15 yillik tajriba ega komik faoliyatini ComedyUz
                loyihasida boshlagan. Undan tashqari, Mirshakar Fayzulloyev MTV
                SHOW, “Tungi qahramon” dasturlari, “Shakkar yil yakuni”
                formatidagi chiqishlari, “Borim shu” va “Malades” nomli
                konsertlari orqali ko‘plab muhlislar orttirgan.
              </p>
              <br />
              <p>
                U oʻz chiqishlarida asosan ijtimoiy-siyosiy masalalarni satira
                ostiga oladi. Hazillarini shunchaki tanqidiy emas, balki chuqur
                tahliliy asosda yozishi tomoshabinning diqqatini oʻziga tortadi.
              </p>
            </div>
          </div>
          <div id="afisha" className="section">
            <div className="container">
              <h3>konsertlar</h3>
              <div className="concerts_wrapper">
                {concerts.map((concert) => (
                  <a
                    href={concert.url}
                    className="concert_item"
                    key={concert.url}
                  >
                    <div>
                      <div>{concert.city}</div>
                      <div className="date">{concert.date}</div>
                    </div>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-chevron-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                        />
                      </svg>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className="section">
            <div className="container youtube_vids">
              <div className="yt_embedded_wrapper">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/exX4mw3FKdQ"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="yt_embedded_wrapper">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/VB72bxCOKgY?si=SRlQaOl6SxFZdkFo"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
          <div className="section">
            <div className="container text-center">
              <h4 className="">Ijtimoiy tarmoqlarda kuzatib boring</h4>
              <div className="social_wrapper">
                <a
                  href="https://www.instagram.com/f.mirshakar?igsh=MTNiZmQ0bDhhZGtkZw=="
                  className="social_item"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="52"
                    height="52"
                    class="bi bi-instagram"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                  </svg>
                </a>
                <a
                  href="https://x.com/f_mirshakar?s=21&t=6FOjRiF87fvh9u-Qtvm3xQ"
                  className="social_item"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="52"
                    height="52"
                    class="bi bi-twitter-x"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                  </svg>
                </a>
                <a href="https://t.me/mirshakar" className="social_item">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="52"
                    height="52"
                    class="bi bi-telegram"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </Suspense>
    </>
  );
}

export default App;
